/* eslint-disable camelcase */
import { clearUser } from 'containers/User/ducks'
import { setError } from 'containers/Messages/ducks'
import { dispatch } from 'store'
import messages from './messages'

export const checkOk = res => (res.ok ? res : Promise.reject(res))

// json :: JSON -> Object
export const json = res =>
  res.headers.get('content-type').indexOf('application/json') !== -1
    ? res.json()
    : res

export const handleNotAuthenticated = res => {
  if (res.status === 401) {
    dispatch(clearUser())
  }
  return res
}

export const handleCustomErrors = async res => {
  if (!res.ok && res.status !== 401) {
    const body = await res.json()
    const { error_code, error_desc } = body

    if (messages[error_code]) {
      error(messages[error_code])
    } else if (error_code && error_desc) {
      error(generateError(body))
    } else {
      error(messages[res.status] || messages.genericError)
    }

    return Promise.reject(res)
  }
  return res
}

const generateError = ({ error_code, error_desc }) => ({
  id: `api.errorMessages.${error_code}`,
  defaultMessage: error_desc,
})

const error = e => dispatch(setError(e))
