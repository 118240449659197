import React from 'react'
import { node, bool, func, string } from 'prop-types'
import {
  Container,
  MessageText,
  IconWrapper,
  Button,
  Close,
} from './components'

const Message = ({ children, error, close, show }) => {
  const timeout = show && setTimeout(close, 4000)
  const handleClick = () => {
    clearTimeout(timeout)
    close()
  }

  return (
    <Container error={error} in={Boolean(show)}>
      <MessageText>{children}</MessageText>
      <IconWrapper>
        <Button onClick={handleClick}>
          <Close />
        </Button>
      </IconWrapper>
    </Container>
  )
}
Message.propTypes = {
  children: node,
  error: bool,
  close: func,
  show: string,
}
Message.defaultProps = { error: false }

export default Message
