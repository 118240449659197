import { createGlobalStyle } from 'styled-components'
import { colors, fontSizes } from 'styles/theme'

import CutiveMonoTTF from './fonts/cutive-mono-v6-latin-regular.ttf'
import CutiveMonoWOFF from './fonts/cutive-mono-v6-latin-regular.woff'
import CutiveMonoWOFF2 from './fonts/cutive-mono-v6-latin-regular.woff2'
import CutiveMonoEOT from './fonts/cutive-mono-v6-latin-regular.eot'
import CutiveMonoSVG from './fonts/cutive-mono-v6-latin-regular.svg'

import AcuminBlack from './fonts/acuminproextracond-black.otf'

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');
  @import url('https://fonts.googleapis.com/css?family=Anton');

  @font-face {
    font-family: 'Cutive Mono';
    src: local('Cutive Mono Regular'), local('CutiveMono-Regular'),
    url(${CutiveMonoEOT}?#iefix) format("embedded-opentype"),
      url(${CutiveMonoWOFF}) format("woff"),
      url(${CutiveMonoWOFF2}) format("woff2"),
      url(${CutiveMonoTTF}) format('truetype');
      url(${CutiveMonoSVG}#'Cutive Mono' format("svg");
    font-weight: 400;
    font-style: normal;
  }
   @font-face {
    font-family: 'Acumin Pro ExtraCondensed';
    src: local('Acumin Pro Extra Condensed'),
      url(${AcuminBlack}) format("opentype");
    font-weight: 700;
  }
  html,
  body {
    height: 100%;
    width: 100%;
    ${fontSizes.small};
    color: ${colors.textDark};
    -webkit-overflow-scrolling: touch;
  }
  * {
    font-family:  'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    box-sizing: border-box;
  }
  #app {
    min-height: 100%;
    min-width: 100%;
    height: 100vh;
    position: relative;
   
  }
  label {
    line-height: 1em;
  }
  a {
    color: ${colors.primary}
  }
  a:hover {
    color: ${colors.primaryDark}
  }
`
