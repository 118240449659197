import createHistory from 'history/createBrowserHistory'
import configureStore from './configureStore'

// Create redux store with history
const initialState = {}
export const history = createHistory()

const store = configureStore(initialState, history)
export const { dispatch } = store
export default store
