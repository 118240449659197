/* eslint-disable camelcase */
import { call, put, select } from 'redux-saga/effects'
import { get, update } from 'api'
import { updateSubResident, setSubResidents } from '../ducks'

const endpoint = '/sub_residents'

const deactivateSubResident = (id, body) =>
  update(`${endpoint}/${id}/deactivate`, body)
const activateSubResident = (id, body) =>
  update(`${endpoint}/${id}/activate`, body)

const getUser = s => s.user

export function* fetchSubResidentsSaga(action) {
  try {
    const subResidents = yield call(get, endpoint, action)
    yield put(setSubResidents(subResidents))
  } catch (e) {
    // using global error handler
  }
}

export function* deactivateSubResidentSaga(action) {
  try {
    const user = yield select(getUser)
    const { id: apartment_id } = user.apartment
    const subResident = yield call(deactivateSubResident, action.payload, {
      apartment_id,
    })
    yield put(updateSubResident(subResident))
  } catch (e) {
    // using global error handler
  }
}

export function* activateSubResidentSaga(action) {
  try {
    const user = yield select(getUser)
    const { id: apartment_id } = user.apartment
    const subResident = yield call(activateSubResident, action.payload, {
      apartment_id,
    })
    yield put(updateSubResident(subResident))
  } catch (e) {
    // using global error handler
  }
}
