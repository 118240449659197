import React from 'react'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import {
  getUser,
  getSessionValidated,
  getLoaded,
  getApartmentId,
  validateSession,
} from 'containers/User/ducks'

const withSession = WrappedComponent =>
  class InjectSaga extends React.Component {
    static WrappedComponent = WrappedComponent

    static displayName = `withSession(${WrappedComponent.displayName ||
      WrappedComponent.name ||
      'Component'})`

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

const mapStateToProps = state => {
  const { user } = getUser(state)
  return {
    user,
    apartmentId: getApartmentId(state),
    loggedIn: Boolean(user.id),
    sessionValidated: getSessionValidated(state),
    isLoaded: getLoaded(state),
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ validateSession }, dispatch)

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)

export default compose(
  withConnect,
  withSession,
)
