import autodux, { id } from 'ducks'
import { assign, apply } from 'ducks/utils'

const getInitialState = () => ({
  order: undefined,
  transaction: undefined,
  selected: {},
  reserved: {},
  confirmingReservation: false,
  confirmedReservation: null,
  service: {},
  loading: false,
})

export const {
  reducer,
  initial,
  slice,
  actions: {
    initPayment,
    setTransaction,
    setOrder,
    fetchOrder,
    setService,
    reserve,
    select,
    confirmOrder,
    confirmOrderSuccess,
    confirmOrderFailed,
    resetState,
    orderPaid,
    showCalendar,
    trackPaymentProvider,
    setLoading,
  },
  selectors: { getValue, getReserved, getService, getSelected },
} = autodux({
  slice: 'orders',
  initial: getInitialState(),
  actions: {
    initPayment: id,
    resetState: getInitialState,
    reserve: assign('reserved'),
    select: assign('selected'),
    confirmOrder: assign('reservation', {
      confirmingReservation: true,
    }),
    confirmOrderSuccess: assign('confirmedReservation', {
      confirmingReservation: true,
    }),
    confirmOrderFailed: apply({ confirmingReservation: false }),
    orderPaid: id, // for analytics
    showCalendar: id, // for analytics
    trackPaymentProvider: id, // for analytics
  },
  selectors: {
    getValue: id,
  },
})
