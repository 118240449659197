import styled from 'styled-components'
import buttonStyle from 'components/Buttons/generalStyle'
import { colors } from 'styles/theme'

export const WhiteButton = styled.button`
  position: relative;
  ${buttonStyle};
  border: 1px solid ${p => colors[p.color || 'borderDark']};
  color: ${p => colors[p.color || 'textDark']};
  background-color: ${colors.white};
  :hover {
    background-color: ${colors.backgroundDark};
    color: ${p => colors[p.color || 'textDark']};
  }
  :disabled {
    cursor: not-allowed;
    color: ${colors.textLighter};
    border-color: ${colors.backgroundDark};
    background-color: ${colors.white};
  }
`

export default WhiteButton
