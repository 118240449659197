/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import languageProviderReducer from 'containers/LanguageProvider/reducer'
import {
  reducer as userReducer,
  slice as userSlice,
} from 'containers/User/ducks'
import {
  reducer as staticReducer,
  slice as staticSlice,
} from 'containers/StaticData/ducks'
import {
  reducer as ordersReducer,
  slice as ordersSlice,
} from 'containers/Orders/ducks'

import { gtmMetaReducer } from './googleTagManager'

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(history, injectedReducers = {}) {
  return combineReducers({
    router: connectRouter(history),
    language: languageProviderReducer,
    gtm: gtmMetaReducer,
    [userSlice]: userReducer,
    [staticSlice]: staticReducer,
    [ordersSlice]: ordersReducer,
    ...injectedReducers,
  })
}
