import autodux, { id } from 'ducks'

const getInitialState = () => ({
  events: [],
  frontpageEvents: [],
  pinnedEvents: [],
  participants: {},
  calendarEntries: [],
  loading: false,
})

export const {
  reducer,
  initial,
  slice,
  actions: {
    fetchEvents,
    fetchEvent,
    fetchFrontpageEvents,
    fetchParticipants,
    fetchCalendarEntries,
    fetchPinnedEvents,
    setEvents,
    setEvent,
    setFrontpageEvents,
    setPinnedEvents,
    setParticipants,
    setCalendarEntries,
    setLoading,
  },
  selectors: { getValue },
} = autodux({
  slice: 'events',
  initial: getInitialState(),
  actions: {
    fetchEvents: state => ({ ...state, loading: true }),
    fetchEvent: id,
    setEvent: (state, payload) => ({
      ...state,
      events: state.events.filter(e => e.id !== payload.id).concat(payload),
    }),
    setParticipants: (state, { eventId, participants }) => ({
      ...state,
      participants: { ...state.participants, [eventId]: participants },
    }),
  },
  selectors: {
    getValue: id,
  },
})
