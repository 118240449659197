import {
  call,
  takeLatest,
  takeEvery,
  put,
  all,
  select,
} from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { format } from 'date-fns'
import { ISODate } from 'formats'
import { get } from 'api'
import {
  slice,
  setEvents,
  setEvent,
  setFrontpageEvents,
  setPinnedEvents,
  setParticipants,
  setCalendarEntries,
  setLoading,
} from './ducks'

const endpoint = '/events'

const getDate = () => format(new Date(), ISODate)

const fetchEvents = params => get(endpoint, params)
const fetchEvent = id => get(`${endpoint}/${id}`)
const fetchFrontpageEvents = () =>
  get(endpoint, {
    _start: 0,
    _end: 7,
    _sort: 'starts_at',
    _order: 'ASC',
    starts_at: getDate(),
    pinned: false,
  })
const fetchPinnedEvents = () =>
  get(endpoint, {
    _sort: 'starts_at',
    _order: 'ASC',
    starts_at: getDate(),
    pinned: true,
  })
const fetchParticipants = id => get(`${`${endpoint}/${id}`}/participants`)
const fetchCalendarEntries = apartment_id =>
  get('/own_calendar_entries', {
    apartment_id,
    start: getDate(),
  })

function* fetchEventsSaga(action) {
  const { debounce, ...payload } = action.payload
  if (debounce) {
    yield delay(debounce)
  }
  try {
    const items = yield call(fetchEvents, payload)
    yield put(setEvents(items))
  } catch (e) {
    // using global error handler
  } finally {
    yield put(setLoading(false))
  }
}

function* fetchEventSaga(action) {
  try {
    const item = yield call(fetchEvent, action.payload)
    yield put(setEvent(item))
  } catch (e) {
    // using global error handler
  }
}

export function* fetchFrontpageEventsSaga() {
  try {
    const items = yield call(fetchFrontpageEvents)
    yield put(setFrontpageEvents(items))
  } catch (e) {
    // using global error handler
  }
}

export function* fetchPinnedEventsSaga() {
  try {
    const items = yield call(fetchPinnedEvents)
    yield put(setPinnedEvents(items))
  } catch (e) {
    // using global error handler
  }
}

function* fetchParticipantsSaga(action) {
  try {
    const participants = yield call(fetchParticipants, action.payload)
    yield put(setParticipants({ eventId: action.payload, participants }))
  } catch (e) {
    // using global error handler
  }
}

function* fetchCalendarEntriesSaga() {
  try {
    const { apartment } = yield select(s => s.user)
    const items = yield call(fetchCalendarEntries, apartment.id)
    yield put(setCalendarEntries(items))
  } catch (e) {
    // using global error handler
  }
}

export default function* eventSagas() {
  yield all([
    takeLatest(`${slice}/fetchEvents`, fetchEventsSaga),
    takeEvery(`${slice}/fetchEvent`, fetchEventSaga),
    takeEvery(`${slice}/fetchFrontpageEvents`, fetchFrontpageEventsSaga),
    takeEvery(`${slice}/fetchPinnedEvents`, fetchPinnedEventsSaga),
    takeEvery(`${slice}/fetchParticipants`, fetchParticipantsSaga),
    takeEvery(`${slice}/fetchCalendarEntries`, fetchCalendarEntriesSaga),
  ])
}
