/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill'
import 'core-js/fn/promise'

// Import all the third party stuff
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import 'sanitize.css/sanitize.css'

// Enable restoring scroll when navigating back
import ScrollMemory from 'react-router-scroll-memory'
// Fix scroll restoration in Firefox
import 'delayed-scroll-restoration-polyfill'

// Import root app
import App from 'containers/App'

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider'

// Load the favicon, the manifest.json file and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicons/favicon.ico'
import '!file-loader?name=[name].[ext]!./images/splash/640x1136.png'
import '!file-loader?name=[name].[ext]!./images/splash/750x1294.png'
import '!file-loader?name=[name].[ext]!./images/splash/750x1334.png'
import '!file-loader?name=[name].[ext]!./images/splash/1242x2148.png'
import '!file-loader?name=[name].[ext]!./images/splash/1125x2436.png'
import '!file-loader?name=[name].[ext]!./images/splash/1536x2048.png'
import '!file-loader?name=[name].[ext]!./images/splash/1668x2224.png'
import '!file-loader?name=[name].[ext]!./images/splash/2048x2732.png'
// logo-white.png is for email templates
import '!file-loader?name=[name].[ext]!./images/logos/logo-white.png'
import 'file-loader?name=[name].[ext]!./.htaccess'
/* eslint-enable import/no-unresolved, import/extensions */

import store, { dispatch, history } from 'store'
import { setNewVersion } from 'containers/Messages/ducks'

// Import i18n messages
import { translationMessages } from './translations/i18n'

// Create redux store with history

const MOUNT_NODE = document.getElementById('app')

const render = messages => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <React.Fragment>
            <ScrollMemory />
            <App />
          </React.Fragment>
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE,
  )
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./translations/i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render(translationMessages)
  })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'))
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err
    })
} else {
  render(translationMessages)
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line global-require
  const runtime = require('offline-plugin/runtime')
  /* eslint-disable no-console */
  runtime.install({
    onUpdating: () => {
      console.log('SW Event:', 'onUpdating')
    },
    onUpdateReady: () => {
      console.log('SW Event:', 'onUpdateReady')
      // Tells to new SW to take control immediately
      runtime.applyUpdate()
    },
    onUpdated: () => {
      console.log('SW Event:', 'onUpdated')
      // Reload the webpage to load into the new version
      dispatch(setNewVersion(true))
    },
    onUpdateFailed: () => {
      console.log('SW Event:', 'onUpdateFailed')
    },
  })
  /* eslint-disable no-console */
}
