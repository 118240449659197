import autodux, { id } from 'ducks'

const getInitialState = () => ({
  user: {
    email: '',
    id: undefined,
    first_name: '',
    last_name: '',
  },
  apartments: {},
  apartment: {},
  subUsers: [],
  subResidents: [],
  orders: [],
  feedbackLessOrders: [],
  sessionValidated: false,
  loaded: false,
})

const loginActions = {
  login: id,
  loginSuccess: state => ({ ...state, sessionValidated: true }),
}

const logoutActions = {
  logout: id,
  clearUser: getInitialState,
}

const apartmentActions = {
  setApartments: (state, apartment) => ({
    ...state,
    apartments: { ...state.apartments, [apartment.id]: apartment },
  }),
}

const subResidentActions = {
  addSubResidentSuccess: (state, payload) => ({
    ...state,
    subResidents: state.subResidents.concat(payload),
  }),
  deactivateSubResident: id,
  activateSubResident: id,
  updateSubResident: (state, payload) => ({
    ...state,
    subResidents: state.subResidents.map(
      user => (user.id === payload.id ? payload : user),
    ),
  }),
}

const orderActions = {
  setOrders: (state, orders) => ({
    ...state,
    orders,
    feedbackLessOrders: orders.filter(o => o.rated === 0 && o.state === 'O'),
  }),
}

export const {
  reducer,
  initial,
  slice,
  actions: {
    login,
    logout,
    loginSuccess,
    clearUser,
    logoutFailure,
    validateSession,
    setUser,
    setApartments,
    addSubResident,
    updateSubResident,
    activateSubResident,
    deactivateSubResident,
    fetchOrders,
    setOrders,
    addSubResidentSuccess,
    setSubResidents,
    setApartment,
    setLoaded,
  },
  selectors: {
    loggedIn,
    getFirstName,
    getEmail,
    getSessionValidated,
    getUser,
    getApartments,
    getValues,
    getSubUsers,
    getFeedbackLessOrders,
    getApartmentId,
    getLoaded,
  },
} = autodux({
  slice: 'user',
  initial: getInitialState(),
  actions: {
    validateSession: id,
    ...logoutActions,
    ...loginActions,
    ...apartmentActions,
    ...subResidentActions,
    ...orderActions,
  },
  selectors: {
    loggedIn: state => Boolean(state && state.user.id),
    getValues: id,
    getApartmentId: state => state.apartment.id,
  },
})
