import autodux, { id } from 'ducks'

const getInitialState = () => ({
  items: [],
  frontpageItems: [],
})

export const {
  reducer,
  initial,
  slice,
  actions: { getBulletins, fetchSuccess, fetchFailure },
  selectors: { getValue, getItems },
} = autodux({
  slice: 'bulletins',
  initial: getInitialState(),
  actions: {
    getBulletins: id,
    fetchSuccess: (state, payload) => ({
      ...state,
      ...payload,
    }),
  },
  selectors: {
    getValue: id,
  },
})
