import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import injectSaga from 'utils/injectSaga'
import { slice, getValues, validateSession, fetchOrders } from './ducks'
import saga from './saga'

const User = ({ children, ...props }) => children(props)

const mapDispatchToProps = dispatch =>
  bindActionCreators({ validateSession, fetchOrders }, dispatch)

const withConnect = connect(
  getValues,
  mapDispatchToProps,
)
const withSaga = injectSaga({ key: slice, saga })

export default withRouter(
  compose(
    withSaga,
    withConnect,
  )(User),
)
