import autodux, { id } from 'ducks'

const getInitialState = () => ({
  error: {},
  success: {},
  newVersion: false,
})

export const {
  reducer,
  slice,
  actions: { setError, setSuccess, clear, setNewVersion },
  selectors: { getState },
} = autodux({
  slice: 'message',
  initial: getInitialState(),
  actions: {
    clear: (state, payload) => ({ ...state, [payload]: '' }),
  },
  selectors: { getState: id },
})
