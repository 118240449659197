import React from 'react'
import { string } from 'prop-types'
import styled from 'styled-components'
import ReactSVG from 'react-svg'
import closeIcon from 'images/icons/close.svg'
import transition from 'styled-transition-group'
import { colors } from 'styles/theme'

export const Container = transition.div.attrs({
  unmountOnExit: true,
  timeout: 300,
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 20px 40px;
  text-align: center;
  z-index:13;
  min-height:72px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:enter {
    opacity: 0.01;
  }
  &:enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  &:exit {
    opacity: 1;
  }
  &:exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
  background-color: ${p => colors[p.error ? 'error' : 'primary']};
`

export const MessageText = styled.p`
  color: ${colors.white};
  font-size: 12px;
  margin: 0;
`

const Svg = ({ className }) => (
  <ReactSVG path={closeIcon} wrapperClassName={className} evalScript="always" />
)
Svg.propTypes = { className: string }

export const Close = styled(Svg)`
  svg {
    width: 21px;
    height: 21px;
    color: ${colors.white};
    cursor: pointer;
  }
`

export const IconWrapper = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  justify-content: flex-end;
  align-items: center;
`

export const Button = styled.button`
  border: none;
  outline: none;
`
