import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from 'containers/Home/Loadable'
import NotFound from 'pages/NotFound/Loadable'
import Login from 'pages/Login/Loadable'
import Register from 'pages/Register/Loadable'
import ResetPass from 'pages/ResetPassword/Loadable'
import Bulletins from 'pages/Bulletins/Loadable'
import Events from 'pages/Events/Loadable'
import Feeds from 'pages/Feeds/Loadable'
import ServiceGroup from 'pages/ServiceGroup/Loadable'
import Services from 'pages/Services/Loadable'
import Profile from 'pages/Profile/Loadable'
import Orders from 'pages/Orders/Loadable'

import Private from './Private'
import Public from './Public'

const renderAdmin = () => {
  window.location = '/admin/'
  return null
}

const Routes = () => (
  <Switch>
    <Private exact footer path="/" component={Home} />
    <Private exact path="/bulletins" component={Bulletins} />
    <Private path="/bulletins/:id" component={Bulletins} />
    <Private path="/events" component={Events} />
    <Private path="/feed" component={Feeds} />
    <Private path="/servicegroups/:id" component={ServiceGroup} />
    <Private path="/services/:id" component={Services} />
    <Private path="/orders/:id" component={Orders} />
    <Private exact path="/profile" component={Profile} />
    <Private exact path="/profile/users" component={Profile} />
    <Private exact path="/profile/orders" component={Profile} />

    <Public exact path="/login" component={Login} />
    <Public exact path="/register" component={Register} />
    <Public exact path="/register/:code" component={Register} />
    <Public exact path="/resetpassword/:code" component={ResetPass} />

    <Route exact strict path="/admin" render={renderAdmin} />

    <Home.Prefetch />
    <Events.Prefetch />
    <Feeds.Prefetch />
    <Bulletins.Prefetch />
    <Profile.Prefetch />
    <Orders.Prefetch />
    <ServiceGroup.Prefetch />
    <Login.Prefetch />
    <Register.Prefetch />

    <Private component={NotFound} />
  </Switch>
)

export default Routes
