/* eslint-disable camelcase */
import { call, takeLatest, put, all, select } from 'redux-saga/effects'
import { post, get } from 'api'
import { entities, getData } from 'containers/StaticData/saga'
import { getBulletinsSaga } from 'containers/Bulletins/saga'
import { fetchFrontpageEventsSaga } from 'containers/Events/saga'
import {
  clearUser,
  slice,
  setUser,
  setApartments,
  setApartment,
  setOrders,
  loginSuccess,
  setLoaded,
} from './ducks'
import {
  fetchSubResidentsSaga,
  activateSubResidentSaga,
  deactivateSubResidentSaga,
} from './sagas/subResidents'

const postLogout = () => post('/logout', {})
const postValidateSession = () => get('/validate_session')

function* logoutSaga() {
  try {
    yield call(postLogout)
    yield put(clearUser())
  } catch (e) {
    yield put(clearUser())
  }
}

function* clearUserSaga(history) {
  yield call(history.push, '/login')
}

function* validateSessionSaga({ history }) {
  try {
    const user = yield call(postValidateSession)
    yield put(setUser(user))
    yield put(loginSuccess(user))
  } catch (e) {
    yield call(history.push, '/login')
  }
}

export function* loginSuccessSaga({ history, location }, action) {
  const { pathname } = location
  const nextLocation =
    pathname === '/login' || pathname === '/register' ? '/' : pathname
  const payload = { apartment_id: action.payload.apartments[0].id }
  yield call(history.replace, nextLocation)
  yield all([
    all(
      action.payload.apartments.map((ap, index) =>
        call(fetchApartmentSaga, { ap, index }),
      ),
    ),
    call(fetchFrontpageEventsSaga),
    call(getBulletinsSaga),
    all(entities.map(entity => call(getData, entity, payload))),
  ])
  yield put(setLoaded(true))
}

function* fetchApartmentSaga({ ap, index }) {
  const { id, primary } = ap
  try {
    const result = yield call(get, `/apartments/${id}`)
    const apartment = { ...result, primary }
    yield put(setApartments(apartment))
    if (index === 0) yield put(setApartment(apartment))
  } catch (e) {
    // using global error handler
  }
}

function* fetchOrdersSaga() {
  const { apartment } = yield select(s => s.user)
  const { id: apartment_id } = apartment
  try {
    const orders = yield call(get, '/orders', { apartment_id })
    yield put(setOrders(orders))
  } catch (e) {
    // using global error handler
  }
}

function* setApartmentSaga(action) {
  const { id: apartment_id } = action.payload
  const payload = { apartment_id }
  yield all([
    call(getData, 'Services', { payload }),
    call(fetchOrdersSaga, { payload }),
    call(fetchSubResidentsSaga, payload),
  ])
}

export default function* loginSagas(context) {
  yield all([
    takeLatest(`${slice}/logout`, logoutSaga),
    takeLatest(`${slice}/clearUser`, clearUserSaga, context.history),
    takeLatest(`${slice}/validateSession`, validateSessionSaga, context),
    takeLatest(`${slice}/loginSuccess`, loginSuccessSaga, context),
    takeLatest(`${slice}/fetchOrders`, fetchOrdersSaga),
    takeLatest(`${slice}/activateSubResident`, activateSubResidentSaga),
    takeLatest(`${slice}/deactivateSubResident`, deactivateSubResidentSaga),
    takeLatest(`${slice}/setApartment`, setApartmentSaga),
  ])
}
