/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
const { addLocaleData } = require('react-intl')
const enLocaleData = require('react-intl/locale-data/en')
const fiLocaleData = require('react-intl/locale-data/fi')
const { DEFAULT_LOCALE } = require('./constants')

const enTranslationMessages = require('./en.json')
const fiTranslationMessages = require('./fi.json')

addLocaleData([...fiLocaleData, ...enLocaleData])

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, fiTranslationMessages)
      : {}
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key]
    return Object.assign(formattedMessages, { [key]: formattedMessage })
  }
  return Object.keys(messages).reduce(flattenFormattedMessages, {})
}

const translationMessages = {
  fi: formatTranslationMessages('fi', fiTranslationMessages),
  en: formatTranslationMessages('en', enTranslationMessages),
}

exports.formatTranslationMessages = formatTranslationMessages
exports.translationMessages = translationMessages
