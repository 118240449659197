import fi from 'date-fns/locale/fi'
import { format, isSameDay, subDays } from 'date-fns'
import { defineMessages } from 'react-intl'
import { intl } from 'translations'

const messages = defineMessages({
  today: {
    id: 'app.dates.today',
    defaultMessage: 'Tänään',
  },
  yesterday: {
    id: 'app.dates.yesterday',
    defaultMessage: 'Eilen',
  },
})

const getLoc = loc => ({ locale: loc || fi })

export const SHORT_DAY = 'eeeeee' // ma
export const LONG_DAY = 'cccc' // maanantai
export const LONGEST_DAY = 'eeee' // maanantaina
export const ISODate = 'yyyy-MM-dd' // 2019-09-20
export const DATE = 'd.M.y' // 20.9.2019
export const LONG_DATE = `${SHORT_DAY} ${DATE}` // ma 20.9.2019
export const LONGER_DATE = `${LONG_DAY} ${DATE}` // maanantai 20.9.2019
export const LONGEST_DATE = `${LONGEST_DAY} ${DATE}` // maanantaina 20.9.2019
export const shortDate = 'd.M.yy' // 20.9.19
export const timeFormat = 'H:mm' // 6:27
export const dateTimeFormat = `${LONG_DATE} ${timeFormat}` // ma 20.9.2019 6:27

export const longDate = (d, loc) => format(d, LONG_DATE, getLoc(loc))
export const longerDate = (d, loc) => format(d, LONGER_DATE, getLoc(loc))
export const longestDate = (d, loc) => format(d, LONGEST_DATE, getLoc(loc))
export const longDateTime = (d, loc) => format(d, dateTimeFormat, getLoc(loc))
export const time = d => format(d, timeFormat)

const getPrefix = d => {
  const now = new Date()
  const yesterday = subDays(new Date(), 1)
  if (isSameDay(d, now)) return `'${intl.formatMessage(messages.today)}'`
  if (isSameDay(d, yesterday))
    return `'${intl.formatMessage(messages.yesterday)}'`
  return LONGEST_DATE
}

export const relativeDateTime = (d, loc) =>
  format(d, `${getPrefix(d)} 'klo' ${timeFormat}`, getLoc(loc)).replace(
    /^\w/,
    c => c.toUpperCase(),
  )
