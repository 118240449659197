import styled, { css } from 'styled-components'
import buttonStyle from 'components/Buttons/generalStyle'
import { colors } from 'styles/theme'

const disabledStyle = css`
  background-color: ${colors.primaryLight};
  :hover {
    background-color: ${colors.primaryLight};
    color: white;
    cursor: not-allowed;
  }
`

const Button = styled.button`
  position: relative;
  color: ${colors.white};
  background-color: ${colors.primary};
  ${buttonStyle};
  &:hover {
    background: ${colors.primaryDark};
    color: white;
  }
  ${({ disabled }) => disabled && disabledStyle};
`

export default Button
