import { createSelector } from 'reselect'

/**
 * Direct selector to the languageToggle state domain
 */
const selectLanguage = state => state.language
const selectLocale = state => selectLanguage(state).locale

/**
 * Select the language locale
 */

const makeSelectLocale = () =>
  createSelector(selectLanguage, languageState => languageState.locale)

export { selectLanguage, selectLocale, makeSelectLocale }
