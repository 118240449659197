import { createMiddleware, createMetaReducer } from 'redux-beacon'
import GoogleTagManager from '@redux-beacon/google-tag-manager'

const emitUserId = action => ({
  event: 'login',
  userId: action.payload.id,
})
const CURRENCY_EUR = 'EUR'

export const SHOW_CALENDAR = 'showCalendar'
const showCalendar = action => ({
  event: 'view_item_list',
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#view_item_list
  ecommerce: {
    item_list_id: action.payload.service_group_id,
    item_list_name: action.payload.name,
    items: [
      {
        item_id: action.payload.id,
        item_name: action.payload.name,
        item_category: action.payload.service_group_name,
      },
    ],
  },
})

export const RESERVE_ORDER = 'reserveOrder'
const reserveOrder = action => ({
  event: 'view_item',
  // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_item_details
  ecommerce: {
    currency: CURRENCY_EUR,
    value: action.payload.price,
    items: [
      {
        item_id: action.payload.service_id,
        item_name: action.payload.service_name,
        item_list_id: action.payload.service_group_id,
        item_category: action.payload.service_group_name,
        price: action.payload.price,
      },
    ],
  },
})

export const CONFIRM_ORDER = 'confirmOrder'
const confirmOrder = action => ({
  event: 'add_to_cart',
  // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_or_remove_an_item_from_a_shopping_cart
  ecommerce: {
    currency: CURRENCY_EUR,
    value: action.payload.price,
    items: [
      {
        item_id: action.payload.service_id,
        item_name: action.payload.service_name,
        item_list_id: action.payload.service_group_id,
        item_category: action.payload.service_group_name,
        price: action.payload.price,
      },
    ],
  },
})

export const BEGIN_CHECKOUT = 'beginCheckout'
const beginCheckout = action => ({
  event: 'begin_checkout',
  ecommerce: {
    currency: CURRENCY_EUR,
    value: action.payload.price,
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#begin_checkout
    items: [
      {
        item_id: action.payload.service_id,
        item_name: action.payload.service_name,
        item_list_id: action.payload.service_group_id,
        item_category: action.payload.service_group_name,
        price: action.payload.price,
      },
    ],
  },
})

export const CLICK_PAYMENT_PROVIDER = 'clickPaymentProvider'
const clickPaymentProvider = action => ({
  event: 'add_payment_info',
  // https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#initiate_the_checkout_process
  ecommerce: {
    currency: CURRENCY_EUR,
    value: action.payload.price,
    payment_type: action.payload.providerName,
    items: [
      {
        item_id: action.payload.service_id,
        item_name: action.payload.service_name,
        item_list_id: action.payload.service_group_id,
        item_category: action.payload.service_group_name,
        price: action.payload.price,
      },
    ],
  },
})

export const PURCHASE_COMPLETED = 'purchaseCompleted'
const purchaseCompleted = action => ({
  event: 'purchase',
  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#purchase
  ecommerce: {
    currency: CURRENCY_EUR,
    value: action.payload.price,
    transaction_id: action.payload.id,
    items: [
      {
        item_id: action.payload.service_id,
        item_name: action.payload.service_name,
        item_list_id: action.payload.service_group_id,
        item_category: action.payload.service_group_name,
        price: action.payload.price,
      },
    ],
  },
})

const emitters = {
  showCalendar,
  reserveOrder,
  confirmOrder,
  beginCheckout,
  clickPaymentProvider,
  purchaseCompleted,
}

const makeActionCreator = type => payload => ({ type, payload })

const actions = [
  SHOW_CALENDAR,
  RESERVE_ORDER,
  CONFIRM_ORDER,
  BEGIN_CHECKOUT,
  CLICK_PAYMENT_PROVIDER,
  PURCHASE_COMPLETED,
]

const makeAction = type => `ga/${type}`

export const gaActions = actions.reduce(
  (obj, type) => ({ ...obj, [type]: makeActionCreator(makeAction(type)) }),
  {},
)

const eventsMap = {
  'user/loginSuccess': emitUserId,
  ...actions.reduce(
    (obj, type) => ({ ...obj, [makeAction(type)]: emitters[type] }),
    {},
  ),
}

const gtm = GoogleTagManager()
export const gtmMiddleware = createMiddleware(eventsMap, gtm)
export const gtmMetaReducer = createMetaReducer(eventsMap, gtm)
