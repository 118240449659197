import { darken, lighten } from 'polished'

const black = '#000000'
const white = '#ffffff'
const green = '#8CC63F'
const lightGreen = '#cbe7bb'
const red = '#F10D48'
const lightRed = '#e8c5c5'
const brown = '#4b3426'
const lightBrown = '#e2dabb'
const lightestGreen = '#F7FBF1'

const grayscale = {
  gray100: '#f2f2f2',
  gray200: '#e5e5e5',
  gray300: '#cccccc',
  gray400: '#9b9b9b',
  gray500: '#666666',
  gray600: '#3c3c3c',
}

const baseColors = {
  black,
  white,
  green,
  lightGreen,
  red,
  lightRed,
  brown,
  lightBrown,
}

const primaryColors = {
  primary: green,
  primaryDark: darken(0.1, green),
  primaryLight: lighten(0.2, green),
  primaryLightest: lightestGreen,
}

const errorColors = {
  error: red,
  errorDark: darken(0.1, red),
  errorLight: lighten(0.45, red),
}

const backgrounds = {
  backgroundDark: grayscale.gray100,
  backgroundDarkest: grayscale.gray600,
}

const text = {
  textLightest: grayscale.gray200,
  textLighter: grayscale.gray300,
  textLight: grayscale.gray400,
  textNormal: grayscale.gray500,
  textDark: grayscale.gray600,
}

const borders = {
  borderLight: grayscale.gray200,
  borderDark: grayscale.gray300,
  darkInputBorder: grayscale.gray400,
}

const calendar = {
  free: lightGreen,
  reserved: lightRed,
  selected: green,
}

const theme = {
  colors: {
    ...baseColors,
    ...grayscale,
    ...primaryColors,
    ...errorColors,
    ...backgrounds,
    ...borders,
    ...text,
    calendar,
  },
  fontSizes: {
    default: 'font-size: 16px',
    small: 'font-size: 14px',
  },
  fontWeights: {
    normal: 'font-weight: 400',
    medium: 'font-weight: 500',
    semibold: 'font-weight: 600',
    bold: 'font-weight: 700',
  },
}

const { colors, fontSizes, fontWeights } = theme

export default theme
export { colors, fontSizes, fontWeights }
