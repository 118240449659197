/**
 * This is a little modified version of Eric Elliott's Autodux
 * https://github.com/ericelliott/autodux
 * to support ImmutableJS
 */

import get from 'lodash/get'
import capitalize from 'lodash/upperFirst'

export const id = x => x
const selectIf = predicate => x => predicate(x) && x
const isFunction = f => typeof f === 'function'
const selectFunction = selectIf(isFunction)

// # Selector creation:
const toGetter = s => `get${capitalize(s)}`
// check if state is JS object or immutableJS Map
const sliceSelector = (slice, fn) => state => fn(state[slice], state)

const createInitSelectors = (slice, initial) =>
  Object.keys(initial).reduce(
    (obj, selector) =>
      slice
        ? Object.assign(obj, {
            [toGetter(selector)]: sliceSelector(slice, state =>
              get(state, selector),
            ),
          })
        : Object.assign(obj, {
            [toGetter(selector)]: state => get(state, selector),
          }),
    {},
  )

const createSliceSelectors = (slice, selectors) =>
  Object.assign(
    Object.keys(selectors).reduce(
      (obj, selector) =>
        slice
          ? Object.assign(obj, {
              [selector]: sliceSelector(slice, selectors[selector]),
            })
          : Object.assign(obj, { [selector]: selectors[selector] }),
      {},
    ),
    {
      [toGetter(slice)]: sliceSelector(slice, id),
    },
  )
// /selector creation

// # Action creation
const getActionName = key => `set${capitalize(key)}`
const getFetchActionName = key => `fetch${capitalize(key)}`

const createAction = (slice, action, key, type = `${slice}/${action}`) => ({
  create: Object.assign(payload => payload, { type }),

  reducer: (state, payload) =>
    Object.assign({}, state, key ? { [key]: payload } : payload),
})

const createFetchAction = (
  slice,
  action,
  key,
  type = `${slice}/${action}`,
) => ({
  create: Object.assign(payload => payload, { type }),
  reducer: state => Object.assign({}, state),
})

const getInitialActions = (slice, initial) =>
  Object.keys(initial).reduce((o, key) => {
    const action = getActionName(key)
    const fetchAction = getFetchActionName(key)
    return Object.assign(o, {
      [action]: createAction(slice, action, key),
      [fetchAction]: createFetchAction(slice, action, key),
    })
  }, {})

const addDefaultActions = (slice, initial, actions) => {
  const initialActions = getInitialActions(slice, initial)
  const action = getActionName(slice)

  return Object.assign(
    {},
    {
      [action]: createAction(slice, action),
    },
    initialActions,
    actions,
  )
}

const createMappedActions = (slice, actions) =>
  Object.keys(actions).reduce(
    (obj, action) =>
      Object.assign({}, obj, {
        [action]: Object.assign(
          (...args) => ({
            type: `${slice}/${action}`,
            payload:
              typeof actions[action].create === 'function'
                ? actions[action].create(...args)
                : args[0],
          }),
          { type: `${slice}/${action}` },
        ),
      }),
    {},
  )
// /action creation

const autodux = ({
  initial = '',
  actions = {},
  selectors = {},
  slice = '',
} = {}) => {
  const allSelectors = Object.assign(
    {},
    createInitSelectors(slice, initial),
    createSliceSelectors(slice, selectors),
  )

  const allActions = createMappedActions(
    slice,
    addDefaultActions(slice, initial, actions),
  )

  const reducer = (state = initial, { type, payload } = {}) => {
    const [namespace, subType] = type
      ? type.split('/')
      : 'unknown/unknown'.split('/')

    const defaultActions = addDefaultActions(slice, initial, {})

    // Look for reducer with top-to-bottom precedence.
    // Fall back to default actions, then undefined.
    // The actions[subType] key can be a function
    // or an object, so we only select the value
    // if it's a function:
    const actionReducer = [
      get(actions, `${subType}.reducer`),
      actions[subType],
      get(defaultActions, `${subType}.reducer`),
    ].reduceRight((f, v) => selectFunction(v) || f)
    // eslint-disable-next-line no-nested-ternary
    return namespace === slice && (actions[subType] || defaultActions[subType])
      ? actionReducer
        ? actionReducer(state, payload)
        : Object.assign({}, state, payload)
      : state
  }

  return {
    initial,
    reducer,
    slice,
    selectors: allSelectors,
    actions: allActions,
  }
}

export default autodux
export * from './utils'
