import { curry } from 'ramda'
import {
  json,
  handleNotAuthenticated,
  handleCustomErrors,
  checkOk,
} from './handlers'
import { getUrl, getUrlWithParams, getOptions, formOptions } from './helpers'
// eslint-disable-next-line no-unused-vars
import { trace } from './utils'

// request :: String -> Object -> Object
const request = (endpoint, options = {}) =>
  fetch(endpoint, options)
    .then(handleNotAuthenticated)
    .then(handleCustomErrors)
    .then(checkOk)
    .then(json)

// get :: String -> Object -> Object
export const get = (endpoint, params = {}) =>
  request(getUrlWithParams(endpoint, params), getOptions())

// formRequest :: String -> String -> Object -> Object
export const formRequest = curry((method, endpoint, body) =>
  request(getUrl(endpoint), formOptions(method, body)),
)

export const post = formRequest('post')
export const update = formRequest('put')
export const remove = formRequest('delete')
