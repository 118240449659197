import autodux from 'ducks'

const initial = {
  serviceCategories: [],
  serviceGroups: [],
  services: [],
  serviceProviders: [],
  eventCategories: [],
  eventPictures: [],
}

export const { reducer, slice, actions, selectors } = autodux({
  slice: 'staticData',
  initial,
})
