import React from 'react'
import { func } from 'prop-types'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import Message from 'components/Message'
import { messageShape } from 'types'
import injectReducer from 'utils/injectReducer'
import {
  reducer,
  slice,
  getState,
  clear as clearAction,
  setNewVersion as setNewVersionAction,
} from './ducks'
import NewVersionAvailable from './NewVersionAvailable'

const Messages = ({
  success = {},
  error = {},
  clear,
  newVersion,
  setNewVersion,
}) => (
  <React.Fragment>
    <Message show={error.id} error close={() => clear('error')}>
      {error.id && <FormattedMessage {...error} />}
    </Message>
    <Message show={success.id} close={() => clear('success')}>
      {success.id && <FormattedMessage {...success} />}
    </Message>
    <NewVersionAvailable show={newVersion} close={() => setNewVersion(false)} />
  </React.Fragment>
)

Messages.propTypes = { clear: func, success: messageShape, error: messageShape }

const mapStateToProps = state => getState(state) || {}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { clear: clearAction, setNewVersion: setNewVersionAction },
    dispatch,
  )

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)
const withReducer = injectReducer({ key: slice, reducer })

export default compose(
  withReducer,
  withConnect,
)(Messages)
