import React from 'react'
import { shape, bool, func, object } from 'prop-types'
import { Redirect } from 'react-router-dom'
import loadable from '@loadable/component'
import { userShape } from 'types'

import withSession from './withSession'

const WithLayout = loadable(() => import('./WithLayout'))

class Private extends React.Component {
  componentWillMount() {
    if (!this.props.sessionValidated) this.props.validateSession()
  }

  render() {
    const {
      component: Component,
      loggedIn,
      sessionValidated = false,
      user,
      ...rest
    } = this.props

    return (
      <React.Fragment>
        {sessionValidated ? (
          <WithLayout
            {...rest}
            render={props =>
              loggedIn ? (
                <Component
                  {...props}
                  user={user}
                  isLoaded={rest.isLoaded}
                  apartmentId={rest.apartmentId}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: { from: props.location },
                  }}
                />
              )
            }
          />
        ) : (
          <WithLayout.Prefetch />
        )}
      </React.Fragment>
    )
  }
}

Private.propTypes = {
  component: object,
  loggedIn: bool,
  location: shape({}),
  validateSession: func,
  sessionValidated: bool,
  user: userShape,
}

export default withSession(Private)
