import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { colors } from 'styles/theme'
import Button from 'components/Buttons/Button'
import WhiteButton from 'components/Buttons/WhiteButton'
import transition from 'styled-transition-group'

export const Toast = transition.div.attrs({
  unmountOnExit: true,
  timeout: 300,
})`
  position: fixed;
  bottom: 30px;
  right: 45px;
  padding: 20px;
  color: ${colors.textDark};
  z-index: 20;
  background: ${colors.white}
  box-shadow: 0 0 14px 0 ${rgba(colors.black, 0.5)};
  @media (max-width: 600px) {
    text-align:center;
    left: 30px;
    right: 30px;
  }
  @media (max-width: 500px) {
    left: 15px;
    right: 15px;
    bottom: 10px;
  }
  @media (max-width: 400px) {
    left: 5px;
    right: 5px;
  }
  &:enter {
    opacity: 0.01;
  }
  &:enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  &:exit {
    opacity: 1;
  }
  &:exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
`

export const Text = styled.p`
  margin: 0;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  text-align: right;
  margin-top: 10px;
  margin-left: -5px;
  margin-right: -5px;
  > * {
    margin-left: 5px;
    margin-right: 5px;
  }
  @media (max-width: 600px) {
    justify-content: center;
  }
`

const buttonStyle = css`
  padding: 4px 10px;
  height: auto;
  font-size: 13px;
`

export const UpdateButton = styled(Button)`
  ${buttonStyle};
`

export const LaterButton = styled(WhiteButton)`
  ${buttonStyle};
`
