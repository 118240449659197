export const apply = object => state => ({ ...state, ...object })

export const assign = (key, object = {}) => (state, payload) => ({
  ...state,
  [key]: payload,
  ...object,
})

export const applySpread = (object = {}) => (state, payload) => ({
  ...state,
  ...payload,
  ...object,
})
