import React from 'react'
import { bool, func } from 'prop-types'
import {
  Toast,
  Text,
  ButtonContainer,
  LaterButton,
  UpdateButton,
} from './components'
import text from './messages'

const NewVersionAvailable = ({ show, close }) => {
  const update = () => {
    close()
    window.location.reload()
  }

  return (
    <Toast in={show}>
      <Text>{text('notification')}</Text>
      <ButtonContainer>
        <LaterButton onClick={close}>{text('laterButton')}</LaterButton>
        <UpdateButton onClick={update}>{text('updateButton')}</UpdateButton>
      </ButtonContainer>
    </Toast>
  )
}

NewVersionAvailable.propTypes = {
  show: bool.isRequired,
  close: func.isRequired,
}

export default NewVersionAvailable
