import {
  string,
  number,
  bool,
  oneOf,
  oneOfType,
  instanceOf,
  shape,
  arrayOf,
} from 'prop-types'

export const userShape = shape(userType)
export const userType = {
  id: string,
  rev: number,
  first_name: string,
  last_name: string,
  phone: string,
  phone_verified: bool,
  email: string,
  email_verified: bool,
  invite_code: string,
}

export const calendarEventShape = shape(calendarEventType)
export const calendarEventType = {
  id: string.isRequired,
  status: oneOf(['free', 'reserved']).isRequired,
  title: string.isRequired,
  start: instanceOf(Date).isRequired,
  end: instanceOf(Date).isRequired,
  slots: arrayOf(slotShape),
}

export const slotShape = shape(slotType)
export const slotType = {
  id: string.isRequired,
  duration_minutes: number.isRequired,
  free_minutes: number.isRequired,
  price: number.isRequired,
  start: string.isRequired,
  end: string.isRequired,
  count: number.isRequired,
}

export const serviceShape = shape(serviceType)
export const serviceType = {
  description: string,
  id: string.isRequired,
  name: string.isRequired,
  reservations_max: number,
  reservations_min: number,
  rev: number,
  service_category_id: string.isRequired,
  service_group_id: string.isRequired,
  service_provider_id: string.isRequired,
  valid_from: oneOfType([instanceOf(Date), string]),
  valid_to: oneOfType([instanceOf(Date), string]),
}

export const serviceGroupShape = shape(serviceGroupType)
export const serviceGroupType = {
  id: string.isRequired,
  ingress_text: string,
  name: string.isRequired,
  picture_icon_id: string,
  picture_tall_id: string,
  picture_wide_id: string,
  rev: number.isRequired,
  service_category_id: string.isRequired,
  service_provider_id: string.isRequired,
  show_picture_box: bool.isRequired,
  show_text_box: bool.isRequired,
  type: number.isRequired,
}

export const serviceCategoryShape = shape(serviceCategoryType)
export const serviceCategoryType = {
  alias: string.isRequired,
  id: string.isRequired,
  name: string.isRequired,
  rev: number.isRequired,
}

export const serviceProviderShape = shape(serviceProviderType)
export const serviceProviderType = {
  description: string,
  id: string.isRequired,
  name: string.isRequired,
}

export const reservationShape = shape(reservationType)
export const reservationType = {
  cancel_terms: string,
  duration_minutes: number.isRequired,
  end: oneOfType([instanceOf(Date), string]),
  start: oneOfType([instanceOf(Date), string]),
  hours: number,
  price: number.isRequired,
  price_includes: string,
  room: string,
  vat: number,
}

export const bulletinShape = shape(bulletinType)
export const bulletinType = {
  attachment_ids: arrayOf(string),
  description: string,
  id: string.isRequired,
  name: string,
  rev: number,
  valid_from: oneOfType([instanceOf(Date), string]),
  valid_to: oneOfType([instanceOf(Date), string]),
}

export const orderShape = shape(orderType)
export const orderType = {
  apartment_id: string.isRequired,
  comments: string,
  duration_minutes: number.isRequired,
  end: oneOfType([instanceOf(Date), string]).isRequired,
  id: string.isRequired,
  price: number.isRequired,
  resident_id: string.isRequired,
  rev: number.isRequired,
  service_id: string.isRequired,
  start: oneOfType([instanceOf(Date), string]).isRequired,
}

export const transactionShape = shape(transactionType)
export const transactionType = {
  href: string,
  providers: arrayOf(providerShape),
  terms: string,
  transactionId: string,
}

export const providerShape = shape(providerType)
export const providerType = {
  id: string,
  group: string,
  name: string,
  icon: string, // url
  svg: string, // url
  url: string, // redirect url
  parameters: arrayOf(shape({})), // shape depends on provider
}

export const eventCategoryShape = shape(eventCategoryType)
export const eventCategoryType = {
  id: string.isRequired,
  name: string.isRequired,
  picture_id: string.isRequired,
  order: number.isRequired,
}

export const eventPictureShape = shape(eventPictureType)
export const eventPictureType = {
  id: string.isRequired,
  name: string.isRequired,
  order: number.isRequired,
  picture_id: string.isRequired,
  default: bool.isRequired,
}

export const eventShape = shape(eventType)
export const eventType = {
  id: string.isRequired,
  created_by_resident: string,
  created_by_name: string,
  picture_id: string,
  name: string.isRequired,
  description: string,
  event_category_id: string,
  cancelled: bool.isRequired,
  space_order_id: string,
  place: string,
  slot_id: string,
  starts_at: oneOfType([instanceOf(Date), string]).isRequired,
  ends_at: oneOfType([instanceOf(Date), string]).isRequired,
  created_at: oneOfType([instanceOf(Date), string]).isRequired,
}

export const commentShape = shape(commentType)
export const commentType = {
  id: string.isRequired,
  resident_id: string,
  resident_name: string,
  apartment_name: string,
  target_type: string,
  target_id: string,
  text: string,
  deleted: bool,
  created_at: oneOfType([instanceOf(Date), string]).isRequired,
}

export const participantShape = shape(participantType)
export const participantType = {
  name: string.isRequired,
  apartment_name: string.isRequired,
  participates: bool.isRequired,
  operator: bool.isRequired,
}

export const apartmentShape = shape(apartmentType)
export const apartmentType = {
  id: string.isRequired,

  address: string.isRequired,
  zip_code: string.isRequired,
  city: string.isRequired,
  floor: number.isRequired,
  rooms: number.isRequired,
  floor_size: number.isRequired,
  primary: bool.isRequired,
}

export const feedShape = shape(feedType)
export const feedType = {
  id: string.isRequired,
  name: string.isRequired,
  description: string.isRequired,
  picture_id: string,
  show_max: number,
  show_days: number,
}

export const messageShape = shape(messageType)
export const messageType = {
  id: string.isRequired,
  defaultMessage: string.isRequired,
}
