import { defineMessages } from 'react-intl'
import { txt } from 'translations'

export default txt(
  defineMessages({
    notification: {
      id: 'app.components.NewVersionNotifier.notification',
      defaultMessage:
        'Uusi versio sovelluksesta saatavilla. Päivitä sivu ottaaksesi sen käyttöön.',
    },
    updateButton: {
      id: 'app.components.NewVersionNotifier.updateButton',
      defaultMessage: 'Päivitä',
    },
    laterButton: {
      id: 'app.components.NewVersionNotifier.laterButton',
      defaultMessage: 'Myöhemmin',
    },
  }),
)
