import { css } from 'styled-components'
import { fontSizes } from 'styles/theme'

export default css`
  height: 32px;
  ${fontSizes.small};
  font-weight: 500;
  white-space: nowrap;
  text-transform: uppercase;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  outline: none;
  transition: all 200ms linear;
  user-select: none;
  text-decoration: none;
  display: flex;
  flex-shrink: 0; // iefix
  align-items: center;
  justify-content: center;
  line-height: 1;
  display: -webkit-box;
  -webkit-box-orient: horizontal;
`
