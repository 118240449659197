import { reduce, compose, curry } from 'ramda'

const API_URL = '/api/v1/web'

// getParamString :: String -> [String, String] -> String
const getParamString = (paramString, [key, param]) =>
  `${paramString}?${key}=${param}`

// processParams :: [[String, String]] -> String
const processParams = reduce(getParamString, '/')

// getEntries :: Object -> [[String, String]]
const getEntries = object => Object.entries(object)

// getParams :: Object -> String
const getParams = compose(
  processParams,
  getEntries,
)

// getOptions :: -> Object
export const getOptions = () => ({
  method: 'get',
  credentials: 'same-origin',
})

// postOptions :: Object -> Object
export const formOptions = curry((method, body) => ({
  method,
  body: JSON.stringify(body),
  credentials: 'same-origin',
  headers: {
    'content-type': 'application/json',
  },
}))

// getUrl :: String -> String
export const getUrl = endpoint => `${API_URL}${endpoint}`

// getUrl :: String -> Object -> String
export const getUrlWithParams = (endpoint, params) =>
  `${getUrl(endpoint)}${getParams(params)}`
    .replace(/\?/g, '&') // Replace all query "?" with "&"
    .replace('&', '?') // Re-replace first "&" with "?"
