/*
 * Bulletins Messages
 *
 * This contains all the text for the Bulletins component.
 */
import { defineMessages } from 'react-intl'

export default defineMessages({
  ERR0001: {
    id: 'api.errorMessages.ERR0001',
    defaultMessage: 'Kutsukoodi on virheellinen',
  },
  ERR0002: {
    id: 'api.errorMessages.ERR0002',
    defaultMessage:
      'Ei voi lisätä tämänhetkistä asukasta uudelleen. Käytä sen sijaan Muokkaa-toimintoa.',
  },
  ERR0003: {
    id: 'api.errorMessages.ERR0003',
    defaultMessage: 'Asunnolle ei löytynyt omistajuutta',
  },
  ERR0004: {
    id: 'api.errorMessages.ERR0004',
    defaultMessage: 'Käyttäjätunnus tai salasana väärin',
  },
  ERR0006: {
    id: 'api.errorMessages.ERR0006',
    defaultMessage: 'Omaan tapahtumaan ei voi olla osallistumatta',
  },
  ERR0008: {
    id: 'api.errorMessages.ERR0008',
    defaultMessage: 'Tapahtumaa ei löydy',
  },
  400: {
    id: 'api.errorMessages.400',
    defaultMessage: 'Pyyntö on virheellinen',
  },
  genericError: {
    id: 'api.errorMessages.genericError',
    defaultMessage: 'Tapahtui virhe',
  },
})
