import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import Messages from 'containers/Messages'
import UserContainer from 'containers/User'

// Import global styles
import GlobalStyles from 'styles/globalStyles'
import theme from 'styles/theme'
import { ThemeProvider } from 'styled-components'
import injectSaga from 'utils/injectSaga'
import ordersSaga from 'containers/Orders/saga'
import { slice as ordersSlice } from 'containers/Orders/ducks'
import eventsSaga from 'containers/Events/saga'
import { slice as eventsSlice } from 'containers/Events/ducks'
import dataSaga from 'containers/StaticData/saga'
import { slice as dataSlice } from 'containers/StaticData/ducks'
import Feedback from './Feedback/Loadable'
import Routes from './Routes'

// eslint-disable-next-line react/prefer-stateless-function
class App extends React.PureComponent {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <GlobalStyles />
          <Messages />
          <UserContainer>
            {({ feedbackLessOrders, fetchOrders }) => (
              <React.Fragment>
                {feedbackLessOrders.length > 0 && (
                  <Feedback
                    fetchOrders={fetchOrders}
                    feedbackLessOrders={feedbackLessOrders}
                  />
                )}
                <Feedback.Prefetch />
                <Routes />
              </React.Fragment>
            )}
          </UserContainer>
        </React.Fragment>
      </ThemeProvider>
    )
  }
}

// ordersSaga is needed with services also so it's injected here
const withOrdersSaga = injectSaga({ key: ordersSlice, saga: ordersSaga })
const withEventsSaga = injectSaga({ key: eventsSlice, saga: eventsSaga })
const withDataSaga = injectSaga({ key: dataSlice, saga: dataSaga })

export default withRouter(
  compose(
    withOrdersSaga,
    withEventsSaga,
    withDataSaga,
  )(App),
)
