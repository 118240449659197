import { call, takeEvery, put, all } from 'redux-saga/effects'
import { get } from 'api'
import { slice, fetchSuccess } from './ducks'

const fetchBulletins = (param = '') => get(`/bulletins${param}`)

export function* getBulletinsSaga() {
  try {
    const items = yield call(fetchBulletins)
    const frontpageItems = yield call(fetchBulletins, '_frontpage')
    yield put(fetchSuccess({ items, frontpageItems }))
  } catch (e) {
    // using global error handler
  }
}

export default function* getBulletinsSagas(context) {
  yield all([
    takeEvery(`${slice}/getBulletins`, getBulletinsSaga, context.history),
  ])
}
