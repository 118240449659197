import { call, put, all, takeLatest } from 'redux-saga/effects'
import { get } from 'api'
import { actions, slice } from './ducks'

export const entities = [
  'ServiceCategories',
  'ServiceGroups',
  'Services',
  'ServiceProviders',
  'EventPictures',
]

const fetchers = {
  ServiceCategories: () => get('/service_categories'),
  ServiceGroups: () => get('/service_groups'),
  Services: ({ apartment_id }) => get('/services', { apartment_id }),
  ServiceProviders: () => get('/service_providers'),
  EventPictures: () => get('/event_pictures'),
}

export function* getData(entity, action) {
  try {
    const items = yield call(fetchers[entity], action.payload)
    yield put(actions[`set${entity}`](items))
  } catch (e) {
    // using global error handler
  }
}

export default function* dataSagas() {
  yield all(
    entities.map(entity =>
      takeLatest(`${slice}/fetch${entity}`, getData, entity),
    ),
  )
}
