import React from 'react'
import { shape, bool, func } from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import withSession from './withSession'

class Public extends React.PureComponent {
  static propTypes = {
    component: func,
    loggedIn: bool,
    location: shape({}),
    validateSession: func,
    sessionValidated: bool,
  }

  render() {
    const { component: Component, loggedIn, ...rest } = this.props
    return (
      <Route
        {...rest}
        render={props =>
          loggedIn ? (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
              }}
            />
          ) : (
            <Component {...props} />
          )
        }
      />
    )
  }
}

export default withSession(Public)
